<template>
  <div>
    <ProductListSlider
      v-for="proposal in proposals"
      :key="proposal.id"
      :proposal="proposal"
      :sort="sort"
      :title="title ? title : proposal.descr"
      :paginationClass="paginationClass + proposal.id"
      :cols="cols"
      :sm="sm"
      :md="md"
      :lg="lg"
      @emptyslider="onEmptySlider"
    >
      <template v-if="isCardMode(proposal)" #firstCard>
        <ProposalCard :proposal="proposal" />
      </template>
    </ProductListSlider>
  </div>
</template>
<script>
import ProposalCard from "@/components/proposal/ProposalCard.vue";
import ProductListSlider from "@/components/product/ProductListSlider.vue";
import get from "lodash/get";

export default {
  name: "ProposalProductSlider",
  components: {
    ProductListSlider,
    ProposalCard
  },
  props: {
    title: {
      type: String,
      default: ""
    },
    proposals: {
      type: Array,
      required: true
    },
    paginationClass: {
      type: String,
      required: false,
      default: "swiper-pagination"
    },
    sort: { type: String, required: false },
    cols: { type: Number, default: 1 },
    sm: { type: Number, default: 2 },
    md: { type: Number, default: 3 },
    lg: { type: Number, default: 4 }
  },
  methods: {
    isCardMode(proposal) {
      return get(proposal, "metaData.category_proposal_type.MODE") == "card";
    },
    onEmptySlider() {
      this.$emit("no-proposals-data");
    }
  }
};
</script>
