<template>
  <v-card
    class="d-flex flex-column justify-space-around"
    rounded="sm"
    elevation="0"
    flat
    :style="{
      color: color,
      backgroundColor: backgroundColor
    }"
    :href="link"
    @click.prevent="handleLink"
    v-intersect.once="handleView"
  >
    <img :src="src" :title="'logo' + proposal.descr" />
    <v-card-text
      class="pre-slide-text"
      v-html="proposal.content"
      :style="{
        color: color
      }"
    ></v-card-text>
  </v-card>
</template>
<style scoped lang="scss"></style>
<script>
import get from "lodash/get";
import banner from "~/mixins/banner";

export default {
  name: "ProposalCard",
  props: ["proposal"],
  mixins: [banner],
  computed: {
    color() {
      return get(this.proposal, "metaData.category_proposal_type.COLOR");
    },
    backgroundColor() {
      return get(this.proposal, "metaData.category_proposal_type.BG_COLOR");
    },
    src() {
      let prop =
        "metaData.category_proposal_type." +
        (this.$vuetify.breakpoint.xs ? "IMAGE_MOBILE" : "IMAGE_DESKTOP");
      return get(this.proposal, prop, this.proposal.img);
    }
  }
};
</script>
